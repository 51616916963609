import * as React from "react";

// Global Layout
import Layout from "../components/layout/layout";

// Components
import Hero from "../components/hero/hero";
import FullWidthContainer from "../components/full-width-container/full-width-container";
import NestedContainer from "../components/nested-container/nested-container";
import Row6633 from "../components/row/row-66-33";
import RowEqual from "../components/row/row-equal";
import SectionHeader from "../components/section-header/section-header";
import { StaticImage } from "gatsby-plugin-image";
import TestimonialCallout from "../components/testimonial-callout/testimonial-callout";
import VideoModal from "../components/video-modal/video-modal";

// Images
import playIcon from "../images/customers/Icon-Play-Tangerine.svg";
import classImg from "../images/customers/Video-See-How-BlueRI-for-Duals-Helps.jpg";
import classImg2 from "../images/customers/Video-Your-Blue-Store-Studio.jpg";

//Stylesheets
import "./customers.css";

const CustomersPage = () => {
  return (
    <Layout pageTitle="2021 BCBSRI Mission Report – Customers">
      <div className="customers-page">
        <FullWidthContainer singleHero background={`var(--white-to-blue)`}>
          <Hero
            title="Customers"
            intro="When a parent takes their child to the doctor, or a patient faces a new diagnosis, they need support. Our members and customers trust us to make quality healthcare easier to find and easier to understand. Whether we’re answering questions or delivering groceries, we’re building relationships to help our members live their best lives."
            reportType="customers"
            inner
            partners
            community
          >
            <StaticImage
              src="../images/customers/Image-Hero.jpg"
              alt="women looking at photo album"
            />
          </Hero>
        </FullWidthContainer>

        <div id="earning-members-trust">
          <FullWidthContainer background={`var(--blue-to-white)`}>
            <SectionHeader
              reportType={"customers"}
              title={"Earning members’ trust"}
              intro={
                "BCBSRI members rewarded our focus on their health and well-being by giving us top marks on multiple surveys."
              }
            />
            <NestedContainer>
              <div style={{ marginTop: "-4rem", marginBottom: "3rem" }}>
                <TestimonialCallout
                  unstyled
                  quote="We take great pride in helping our members get the most from their health plan. Satisfaction with their plan means members are more likely to use their benefits to reach their health goals."
                  author="Melissa Cummings"
                  role="Chief Customer Officer, BCBSRI"
                />
              </div>
            </NestedContainer>

            <NestedContainer background={`var(--white)`} hasShadow padded>
              <SectionHeader
                small
                center
                title={"5-Star rating for Medicare plans"}
                intro={
                  "As a result of member feedback, our 2022 Medicare Advantage plans have earned the highest rating from the Centers for Medicare and Medicaid Services (CMS): 5-out-of-5 Stars. BCBSRI is the only Rhode Island health insurer to receive 5-out-of-5 Stars for all 2022 Medicare Advantage plans."
                }
              />
              <Row6633 reverse>
                <div className="flex-pad">
                  <h4 className="colored-heading left">
                    We’re proud to say we received 5-Stars in the following
                    member-focused categories:
                  </h4>
                  <ul>
                    <li>Customer service</li>
                    <li>Staying healthy: Screenings and tests</li>
                    <li>High levels of member retention</li>
                  </ul>
                  <p className="lighter">
                    Members enrolled in 5-Star plans generally experience lower
                    out-of-pocket costs, high member satisfaction, better health
                    outcomes, and best-in-class service.
                  </p>
                  <a
                    className="colored-link"
                    href="https://www.bcbsri.com/medicare/shop/star-ratings"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Why 5-Star matters for our Medicare members
                  </a>
                  <p
                    className="italic lighter"
                    style={{ paddingTop: "2rem", marginBottom: "0" }}
                  >
                    <a
                      href="https://www.bcbsri.com/medicare/shop/2022"
                      target="_blank"
                      rel="noreferrer"
                      className="standard-link"
                    >
                      For more details about any of our 2022 5-Star plans, visit
                      our Medicare site.
                    </a>
                  </p>
                </div>
                <div className="flex-pad">
                  <StaticImage
                    src="../images/customers/Illustration-5-Star-Rating.png"
                    alt="hands raised to vote"
                  />
                </div>
              </Row6633>
              <div style={{ marginTop: "3rem" }}>
                <TestimonialCallout
                  quote="Blue Cross offers many benefits that really help promote wellness. Thank you for helping keep me fit."
                  author="Ken Camilucci"
                  role="BCBSRI member"
                />
              </div>
            </NestedContainer>
            <NestedContainer padded>
              <Row6633 reverse>
                <div>
                  <StaticImage
                    src="../images/customers/Image-JD-Power.png"
                    alt="JD Power award"
                    height={297}
                    layout="constrained"
                  />
                </div>
                <div>
                  <h3 className="left">
                    #1 in member satisfaction — J.D. Power
                  </h3>
                  <p className="lighter">
                    Highest member satisfaction among commercial health plans in
                    the Northeast Region. BCBSRI achieved the highest scores in
                    the following factors:
                  </p>
                  <ul>
                    <li>Coverage and Benefits</li>
                    <li>Provider Choice</li>
                    <li>Information and Communication</li>
                  </ul>
                  <p className="italic lighter">
                    For J.D. Power 2021 award information, visit
                    jdpower.com/awards.
                  </p>
                </div>
              </Row6633>
            </NestedContainer>
            <NestedContainer background={"#003E51"} padded>
              <Row6633 doubleGap>
                <div className="item--light">
                  <h3 className="left" style={{ color: `var(--white)` }}>
                    High rating from NCQA again
                  </h3>
                  <p>
                    We earned a 4.5 out of 5 rating on our preferred provider
                    organization (PPO) products from the National Committee for
                    Quality Assurance (NCQA) for the seventh time, recognizing
                    our commitment to service and clinical quality. The rating
                    of BCBSRI PPO products is part of the Private Health
                    Insurance Plan Ratings for 2021.
                  </p>
                  <p>
                    This makes BCBSRI one of only 12 plans in the country to
                    have received a score of 4.5 or above.
                  </p>
                  <figure className="testimonial">
                    <span className="testimonial__border"></span>
                    <blockquote>
                      “The 4.5 rating is also a testament to our ongoing
                      collaboration with the provider community and how that
                      approach can result in a better member experience.”
                    </blockquote>
                    <figcaption>
                      <cite>&mdash;&nbsp;Dr. Matt Collins</cite>, Chief Medical
                      Officer, BCBSRI
                    </figcaption>
                  </figure>
                </div>
                <div className="item--light center">
                  <StaticImage
                    src="../images/customers/Logo-NCQA.png"
                    width={160}
                  />
                  <div className="stat__label">rating</div>
                  <div className="stat">4.5/5</div>
                  <div className="stat__label">top</div>
                  <div className="stat">6%</div>
                  <p className="lighter">out of 274 PPO plans</p>
                </div>
              </Row6633>
            </NestedContainer>
          </FullWidthContainer>
        </div>

        <div id="making-health-more-accessible">
          <FullWidthContainer styleName="" background={`var(--color-17)`}>
            <SectionHeader
              reportType={"customers"}
              title={"Making health more accessible"}
              intro={
                "Our efforts to help more Rhode Islanders rise above the challenges they face and lead healthy lives take many forms, from a new, more inclusive health plan to extra benefits that assist members with basic needs, like food security."
              }
            />
            <NestedContainer padded>
              <Row6633 doubleGap>
                <div>
                  <h3 className="left">New plan for Rhode Islanders</h3>
                  <StaticImage
                    src="../images/customers/Image-New-Plan-for-Rhode-Islanders.jpg"
                    height={438}
                    layout="constrained"
                    className="rounded"
                  />
                  <RowEqual styleName="wrap-early">
                    <div className="new-plan">
                      <p>
                        In 2021, we took an important step toward making
                        healthcare more accessible and affordable with a new
                        Dual Eligible Special Needs Plan (D-SNP). It’s designed
                        to support people who need a high level of support and
                        care. BlueRI for Duals (HMO D-SNP) is now available for
                        Rhode Islanders who qualify for both Medicare and
                        Medicaid.
                      </p>
                      <p>
                        In this plan, members work with health navigators who
                        help them reach their health goals and assist with
                        setting up appointments, scheduling transportation, and
                        connecting with community resources for food or housing.
                      </p>
                    </div>
                    <div className="new-plan">
                      <strong>See how BlueRI for Duals helps</strong>
                      <VideoModal
                        ariaHideApp={false}
                        caption={[
                          <button className="btn btn--orange">
                            <span>watch now</span>
                          </button>,
                        ]}
                        posterImage={classImg}
                        videoPlayIcon={playIcon}
                        videoSource="https://player.vimeo.com/video/689326506?h=6544a15bfb"
                        variant={"customer--small"}
                      />
                    </div>
                  </RowEqual>
                  <div className="podcast-cta mobile-space">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 29">
                      <path
                        d="M9 23.951V27H7a1 1 0 0 0 0 2h6a1 1 0 0 0 0-2h-2v-3.049A10 10 0 0 0 20 14v-1a1 1 0 0 0-2 0v1a8 8 0 0 1-16 0v-1a1 1 0 0 0-2 0v1a10 10 0 0 0 9 9.951zM4.34 4c-.115.325-.201.66-.258 1H6.5a.5.5 0 0 1 0 1H4v1h2.5a.5.5 0 0 1 0 1H4v6a6 6 0 1 0 12 0V8h-2.5a.5.5 0 0 1 0-1H16V6h-2.5a.5.5 0 0 1 0-1h2.417a5.949 5.949 0 0 0-.259-1H13.5a.5.5 0 0 1 0-1h1.7A6 6 0 0 0 4.8 3h1.7a.5.5 0 0 1 0 1H4.34z"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        fill="#d04a02"
                      />
                    </svg>
                    <span className="podcast-cta__text">
                      <strong>
                        Hear how this can help more Rhode Islanders
                      </strong>
                    </span>
                    <a
                      href="https://soundcloud.com/therhodetohealth/the-duals-difference-s4-ep16"
                      className="btn btn--orange"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>listen to podcast</span>
                    </a>
                  </div>
                </div>
                <div className="center">
                  <h4
                    className="colored-heading"
                    style={{ textAlign: "center" }}
                  >
                    Who can benefit
                  </h4>
                  <div className="stat__item">
                    <div className="stat__description">Nearly</div>
                    <div className="stat">41,000 </div>
                    <div className="stat__description">
                      Rhode Islanders are fully dual-eligible
                    </div>
                  </div>
                  <div className="stat__item">
                    <div className="stat">9,000</div>
                    <div className="stat__description">
                      have partial Medicaid
                    </div>
                  </div>
                  <div className="stat__description italic">
                    SOURCE: “Data book: Beneficiaries dually eligible for
                    Medicare and Medicaid,” February 2022, MedPAC MACPAC
                  </div>
                  <div className="flex-pad">
                    <h4
                      className="colored-heading"
                      style={{ textAlign: "center" }}
                    >
                      Why Duals can help
                      <br />
                      <span className="sub">
                        How Rhode Islanders rate their well-being
                      </span>
                    </h4>
                    <div className="stat__item">
                      <div className="stat">67 / 100</div>
                      <div className="stat__description">
                        Services for older adults
                      </div>
                    </div>
                    <div className="stat__item">
                      <div className="stat">64 / 100</div>
                      <div className="stat__description">
                        Economic situation
                      </div>
                    </div>
                    <div className="stat__item">
                      <div className="stat">73 / 100</div>
                      <div className="stat__description">Healthcare access</div>
                    </div>

                    <div className="stat__description italic">
                      SOURCE: 2021 RI Life Index
                    </div>
                  </div>
                </div>
              </Row6633>
            </NestedContainer>
            <NestedContainer
              background={`var(--white)`}
              hasShadow
              padded
              styleName=""
            >
              <Row6633 reverse>
                <div>
                  <StaticImage
                    src="../images/customers/Image-Grocery-Delivery.jpg"
                    alt="man delivering groceries"
                    caption="caption goes here"
                    className="rounded"
                  />
                </div>
                <div>
                  <h3 className="left">Groceries at the doorstep</h3>
                  <p>
                    As we learned from the RI Life Index and from surveying our
                    own members, too many older Rhode Islanders are worried
                    about getting enough healthy food. That’s why we continued a
                    successful program started in 2020 that helps our at-risk
                    Medicare members: delivering no-cost groceries to their
                    homes with West Warwick-based Millonzi Fine Catering.
                  </p>
                  <figure className="testimonial">
                    <span className="testimonial__border"></span>
                    <blockquote>
                      &ldquo;It really attacks that food insecurity piece.
                      You’re really helping people from an economic standpoint
                      and also a nutritional standpoint.&rdquo;
                    </blockquote>
                    <figcaption>
                      <strong>&mdash; Kevin Millonzi</strong>, President,
                      Millonzi Fine Catering (as told to WPRI)
                    </figcaption>
                  </figure>
                  <a
                    style={{
                      display: "inline-block",
                      marginTop: "60px",
                    }}
                    className="colored-link"
                    href="https://www.wpri.com/business-news/thousands-eligible-for-new-blue-cross-ri-plan/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    See how groceries help our Medicare members
                  </a>
                </div>
              </Row6633>
            </NestedContainer>
          </FullWidthContainer>
        </div>

        <div id="helping-employers-help-their-workers">
          <FullWidthContainer single background={`var(--color-2)`} dark>
            <NestedContainer padded styleName="">
              <Row6633 reverse>
                <div>
                  <h3 className="text-white text-left">
                    Helping employers help their workers
                  </h3>
                  <p>
                    Our group customers now have an easier way to find the
                    information they need, from plan details to insightful
                    commentary about healthcare trends that will affect their
                    businesses. We redesigned our B2B web presence,
                    incorporating customer research, and we expanded the
                    resources in our Employer Toolkit, where employers can find
                    materials to share with their employees on topics like
                    COVID-19 and behavioral health help.
                  </p>
                </div>
                <div>
                  <StaticImage
                    src="../images/customers/Image-Employer-Toolkit.jpg"
                    alt="Toolkit website on cell phone"
                    className="rounded"
                  />
                </div>
              </Row6633>
            </NestedContainer>
          </FullWidthContainer>
        </div>

        <div id="bringing-your-blue-store-to-the-community">
          <FullWidthContainer styleName="" background={`var(--white)`}>
            <NestedContainer padded paddedLast>
              <Row6633 doubleGap>
                <div class="outdoor-classes-video--desktop">
                  <p>
                    <strong>
                      See why our members appreciate outdoor classes
                    </strong>
                  </p>
                  <VideoModal
                    ariaHideApp={false}
                    caption={[
                      <button className="btn btn--orange">
                        <span>watch now</span>
                      </button>,
                    ]}
                    posterImage={classImg2}
                    videoPlayIcon={playIcon}
                    videoSource="https://player.vimeo.com/video/685713234?h=b5d48d1377"
                    variant={"customer--large"}
                  />
                </div>
                <div>
                  <h3 className="text-left">
                    Bringing Your Blue Store to the community
                  </h3>
                  <p>
                    Our members have enjoyed coming to a Your Blue Store
                    <span>&#8480;</span> location for fitness classes,
                    educational seminars, and in-person help with their plans.
                    And they’ve joined our Facebook group—Your Blue Store
                    Studio—for online classes. In 2021 we broadened our reach
                    and took Your Blue Store into the community with outdoor
                    fitness classes and a foldout store that moved through the
                    community helping customers understand health insurance, in
                    English and Spanish.
                  </p>
                </div>
              </Row6633>
            </NestedContainer>
            <NestedContainer className="bluestore-quote" padded paddedLast>
              <Row6633 verticalCenter>
                <div style={{ marginTop: "3rem" }}>
                  <div style={{ maxWidth: "784px" }}>
                    <TestimonialCallout
                      quote="For older people, it can be harder to get out of chairs or up the stairs. Being active helps a lot, and I’d tell my family and friends to come to the Blue Cross classes."
                      author="Elliott Clement"
                      role="BCBSRI member"
                    />
                  </div>
                </div>
                <div style={{ marginTop: "3rem" }}>
                  <StaticImage
                    src="../images/customers/Image-Mobile-Blue-Store.jpeg"
                    alt="The traveling Your Blue Store setup and welcoming customers."
                    className="rounded"
                  />
                </div>
                <div class="outdoor-classes-video--mobile">
                  <p>
                    <strong>
                      See why our members appreciate outdoor classes
                    </strong>
                  </p>
                  <VideoModal
                    ariaHideApp={false}
                    caption={[
                      <button className="btn btn--orange">
                        <span>watch now</span>
                      </button>,
                    ]}
                    posterImage={classImg2}
                    videoPlayIcon={playIcon}
                    videoSource="https://player.vimeo.com/video/685713234?h=b5d48d1377"
                    variant={"customer--large"}
                  />
                </div>
              </Row6633>
            </NestedContainer>
          </FullWidthContainer>
        </div>

        <div id="delivering-digital-tools-for-members">
          <FullWidthContainer styleName="" background={`var(--color-17)`}>
            <SectionHeader
              reportType={"customers"}
              title={"Delivering digital tools for members"}
              intro={
                "We continue our push to make health insurance and healthcare easier for our members with new tools they can use online or on their phones. That gives members more time to focus on their personal goals. "
              }
            />
            <NestedContainer padded>
              <Row6633 doubleGap>
                <div>
                  <div className="floating-icon-wrap">
                    <StaticImage
                      src="../images/customers/Icon-Phone.svg"
                      alt="phone"
                    />
                  </div>
                  <h3 className="left">myBCBSRI mobile expansion</h3>
                  <p>
                    In 2021 we expanded the digital choices for our members by
                    introducing the myBCBSRI app. Now members can check
                    benefits, review claims, and go paperless—wherever they are.
                  </p>
                  <RowEqual styleName="wrap-early">
                    <div>
                      <ul>
                        <li>One login for website and app</li>
                        <li>Face or Touch ID</li>
                        <li>Digital member ID</li>
                      </ul>
                    </div>
                    <div>
                      <div className="stat">12,600</div>
                      <div
                        className="stat__description"
                        style={{ marginBottom: "1rem" }}
                      >
                        downloads
                      </div>
                    </div>
                  </RowEqual>
                  <StaticImage
                    src="../images/customers/Image-Mobile-Screenshots.png"
                    alt="mobile phone screenshots"
                  />
                  <div className="flex-pad doctors-online-small">
                    <div className="floating-icon-wrap">
                      <StaticImage
                        src="../images/customers/Icon-Doctors-Online.svg"
                        alt="piggy bank"
                      />
                    </div>
                    <h3 className="left">
                      Doctors Online for behavioral health
                    </h3>
                    <p>
                      Our members continued to turn to the various ways they can
                      use telehealth, from phone and video chats with their
                      local doctors to visits on the BCBSRI Doctors Online
                      platform. In 2021 members more than doubled their use of
                      Doctors Online for behavioral health visits from the
                      previous year.
                    </p>
                    <div className="stat__item">
                      <div className="stat">124%</div>
                      <div className="stat__description">
                        increase in Doctors Online behavioral
                        <br />
                        health visits, 2020 to 2021
                      </div>
                    </div>
                    <div className="stat__description italic">
                      Doctors Online is a telemedicine service provided by
                      American Well®, an independent company.
                    </div>
                  </div>

                  <div className="flex-pad smart-shopper-small">
                    <div className="floating-icon-wrap">
                      <StaticImage
                        src="../images/customers/Icon-SmartShopper.svg"
                        alt="piggy bank"
                      />
                    </div>
                    <h3 className="left">SmartShopper savings</h3>
                    <p>
                      BCBSRI introduced another tool to help reduce costs
                      throughout the healthcare system and benefit our members
                      at the same time. With SmartShopper®, members can compare
                      quality, in-network providers and find the most
                      cost-effective location of their choice—and earn up to
                      $500 in cash rewards as a share of the savings.
                    </p>
                    <p className="footnote italic">
                      The SmartShopper program is offered by Sapphire Digital,
                      an independent company.
                    </p>

                    <VideoModal
                      ariaHideApp={false}
                      caption={[
                        <span className="colored-link">
                          See how members can save
                        </span>,
                      ]}
                      videoSource="https://player.vimeo.com/video/685715926?h=3476006413"
                      width={640}
                      height={564}
                      allow="autoplay; fullscreen"
                      variant="video-modal--shoppers"
                    />
                  </div>

                  <div className="floating-icon-wrap flex-pad">
                    <StaticImage
                      src="../images/customers/Icon-Doctor-Enhancements.svg"
                      alt="doctor"
                    />
                  </div>
                  <h3 className="left">Find a Doctor enhancements</h3>
                  <p>
                    Members now can find help more easily with a redesigned Find
                    a Doctor tool that includes intuitive, one-tap searches and
                    more information about specialists.
                  </p>
                  <a
                    className="colored-link"
                    href="https://bcbsri.sapphirecareselect.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    See how simple it is for members
                  </a>
                </div>

                <div>
                  <div className="smart-shopper-large">
                    <div className="floating-icon-wrap">
                      <StaticImage
                        src="../images/customers/Icon-SmartShopper.svg"
                        alt="piggy bank"
                      />
                    </div>
                    <h3 className="left">SmartShopper savings</h3>
                    <p>
                      BCBSRI introduced another tool to help reduce costs
                      throughout the healthcare system and benefit our members
                      at the same time. With SmartShopper®, members can compare
                      quality, in-network providers and find the most
                      cost-effective location of their choice—and earn up to
                      $500 in cash rewards as a share of the savings.
                    </p>
                    <p className="footnote italic">
                      The SmartShopper program is offered by Sapphire Digital,
                      an independent company.
                    </p>

                    <VideoModal
                      ariaHideApp={false}
                      caption={[
                        <span className="colored-link">
                          See how members can save
                        </span>,
                      ]}
                      videoSource="https://player.vimeo.com/video/685715926?h=3476006413"
                      width={640}
                      height={564}
                      allow="autoplay; fullscreen"
                      variant="video-modal--shoppers"
                    />
                  </div>

                  <div className="flex-pad doctors-online-large">
                    <div className="floating-icon-wrap">
                      <StaticImage
                        src="../images/customers/Icon-Doctors-Online.svg"
                        alt="piggy bank"
                      />
                    </div>
                    <h3 className="left">
                      Doctors Online for behavioral health
                    </h3>
                    <p>
                      Our members continued to turn to the various ways they can
                      use telehealth, from phone and video chats with their
                      local doctors to visits on the BCBSRI Doctors Online
                      platform. In 2021 members more than doubled their use of
                      Doctors Online for behavioral health visits from the
                      previous year.
                    </p>
                    <div className="stat__item">
                      <div className="stat">124%</div>
                      <div className="stat__description">
                        increase in Doctors Online behavioral
                        <br />
                        health visits, 2020 to 2021
                      </div>
                    </div>
                    <div className="stat__description italic">
                      Doctors Online is a telemedicine service provided by
                      American Well®, an independent company.
                    </div>
                  </div>
                </div>
              </Row6633>
            </NestedContainer>
            <NestedContainer
              styleName=""
              background={`var(--white)`}
              hasShadow
              padded
            >
              <Row6633 doubleGap>
                <div>
                  <StaticImage
                    src="../images/customers/Image-Amava-Connections.jpg"
                    alt="women walking holding hands"
                    className="rounded"
                  />
                </div>
                <div>
                  <h3 className="left">Amava connections</h3>
                  <p>
                    We want our members to live healthy, fulfilling lives. So,
                    we offer our Medicare members another opportunity for
                    staying active. They can join live, guided discussions on
                    traveling, writing memoirs, and aging independently, to name
                    a few, through Amava Circles, available online—and at no
                    cost. Members also can sign up for volunteer opportunities
                    with leading local and national organizations.
                  </p>
                  <p className="footnote italic">
                    Amava Circles is provided by Amava, an independent company.
                  </p>
                </div>
              </Row6633>
              <div style={{ marginTop: "3rem" }}>
                <TestimonialCallout
                  quote="Amava is a great way to connect with other people. I’ll definitely continue with them even now that things have opened up."
                  author="Sheila"
                  role="BCBSRI Member"
                />
              </div>
            </NestedContainer>
          </FullWidthContainer>
        </div>

        <div id="including-more-rhode-islanders">
          <FullWidthContainer styleName="" background={`var(--white)`}>
            <SectionHeader
              reportType={"customers"}
              title={"Including more Rhode Islanders"}
              intro={
                "Diversity extends beyond the traditional categories of race, gender, and ethnicity, encompassing the rich variety of Rhode Islanders in all forms. Through our actions, we hope we show that we are committed to embracing diversity, inclusion, and equity to best serve the needs of all Rhode Islanders."
              }
            />
            <NestedContainer padded>
              <Row6633 doubleGap>
                <div>
                  <h3 className="left">Recognition for our inclusion</h3>
                  <p>
                    As employers, we have a responsibility to ensure that all of
                    our employees feel safe, respected, and valued at work.
                    We’re proud that our efforts have been recognized by these
                    important groups.
                  </p>
                  <a
                    className="colored-link"
                    href="https://www.bcbsri.com/employers/change/leadership/3-steps-toward-more-inclusive-workplace"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Read why this matters for Rhode Island businesses
                  </a>
                  <div style={{ paddingTop: "3.125rem" }}>
                    <StaticImage
                      src="../images/customers/Image-Inclusion-Diversity.jpg"
                      alt="man smiling"
                      className="rounded"
                    />
                  </div>
                  <RowEqual styleName="wrap-early">
                    <div style={{ paddingTop: "2rem" }}>
                      <h5 style={{ marginBottom: "1rem" }}>
                        Bell Seal for Mental Health
                      </h5>
                      <div className="footnote">Mental Health America</div>
                      <div style={{ paddingTop: "1rem" }}>
                        <button className="btn btn--orange">
                          <span>
                            <a
                              href="https://www.mhanational.org/bestemployers"
                              target="_blank"
                              rel="noreferrer"
                            >
                              learn more
                            </a>
                          </span>
                        </button>
                      </div>
                      <h5 style={{ paddingTop: "3rem", marginBottom: "1rem" }}>
                        2021 Disability Equality Index
                      </h5>
                      <div className="footnote">
                        American Association of People with Disabilities (AAPD)
                        and Disability:IN®
                      </div>
                      <div style={{ paddingTop: "1rem" }}>
                        <button className="btn btn--orange">
                          <span>
                            <a
                              href="https://disabilityin.org/what-we-do/disability-equality-index"
                              target="_blank"
                              rel="noreferrer"
                            >
                              learn more
                            </a>
                          </span>
                        </button>
                      </div>
                    </div>
                    <div style={{ paddingTop: "2rem" }}>
                      <h5 style={{ marginBottom: "1rem" }}>
                        2021 Corporate Equality Index
                      </h5>
                      <div className="footnote">
                        Human Rights Campaign Foundation
                      </div>
                      <div style={{ paddingTop: "1rem" }}>
                        <button className="btn btn--orange">
                          <span>
                            <a
                              href="https://www.hrc.org/resources/corporate-equality-index"
                              target="_blank"
                              rel="noreferrer"
                            >
                              learn more
                            </a>
                          </span>
                        </button>
                      </div>
                      <div
                        className="podcast-cta"
                        style={{ paddingTop: "3rem" }}
                      >
                        <span className="podcast-cta__text">
                          <strong>
                            Listen to our employees discuss the invisibility of
                            disability
                          </strong>
                        </span>
                        <a
                          href="https://soundcloud.com/therhodetohealth/4-29-21-mixdownv-v2a?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing"
                          className="btn btn--orange"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span>listen to podcast</span>
                        </a>
                      </div>
                    </div>
                  </RowEqual>
                </div>
                <div>
                  <h3 className="left">Diversity week celebrations</h3>
                  <p>
                    In 2021 our annual Diversity Week looked at “Our Journey
                    Ahead” and coincided with the Juneteenth holiday. We learned
                    from each other and from outside experts about:
                  </p>
                  <h4
                    className="colored-heading"
                    style={{ paddingTop: "1.5rem" }}
                  >
                    “Black Motherhood Through the Lens”
                  </h4>
                  <p>
                    This documentary portrays four women and the topic of
                    maternal health in the Black community.
                  </p>
                  <h4
                    className="colored-heading"
                    style={{ paddingTop: "1.5rem" }}
                  >
                    Juneteenth Celebration
                  </h4>
                  <p>
                    This coffee chat was led by Black Council @ Blue, one of
                    several employee business resource groups that help us
                    understand issues affecting many diverse groups at BCBSRI.
                  </p>
                  <h4
                    className="colored-heading"
                    style={{ paddingTop: "1.5rem" }}
                  >
                    Caring for the LGBTQ+ Community
                  </h4>
                  <p>
                    We discussed how BCBSRI, Rhode Island’s largest health
                    insurer, can help reduce barriers to care for the older
                    LGBTQ+ population.
                  </p>
                  <div className="podcast-cta" style={{ paddingTop: "3rem" }}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 29">
                      <path
                        d="M9 23.951V27H7a1 1 0 0 0 0 2h6a1 1 0 0 0 0-2h-2v-3.049A10 10 0 0 0 20 14v-1a1 1 0 0 0-2 0v1a8 8 0 0 1-16 0v-1a1 1 0 0 0-2 0v1a10 10 0 0 0 9 9.951zM4.34 4c-.115.325-.201.66-.258 1H6.5a.5.5 0 0 1 0 1H4v1h2.5a.5.5 0 0 1 0 1H4v6a6 6 0 1 0 12 0V8h-2.5a.5.5 0 0 1 0-1H16V6h-2.5a.5.5 0 0 1 0-1h2.417a5.949 5.949 0 0 0-.259-1H13.5a.5.5 0 0 1 0-1h1.7A6 6 0 0 0 4.8 3h1.7a.5.5 0 0 1 0 1H4.34z"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        fill="#d04a02"
                      />
                    </svg>
                    <span className="podcast-cta__text">
                      <strong>Hear why Juneteenth matters</strong>
                    </span>
                    <a
                      href="https://soundcloud.com/therhodetohealth/its-not-just-a-celebration-for-black-people-its-a-celebration-of-american-history-s4-ep9?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing"
                      className="btn btn--orange"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>listen to podcast</span>
                    </a>
                  </div>
                </div>
              </Row6633>
            </NestedContainer>
            <NestedContainer
              styleName=""
              background={`var(--color-16)`}
              padded
              paddedLast
            >
              <Row6633 reverse>
                <div>
                  <StaticImage
                    src="../images/customers/Image-Still-Moving-Forward.jpg"
                    alt="mother and child"
                    className="rounded"
                  />
                </div>
                <div>
                  <h3 className="left">Still driving forward</h3>
                  <p>
                    Beyond awards and celebrations, we continue taking steps to
                    help all Rhode Islanders feel like they belong:
                  </p>
                  <ul>
                    <li>
                      In July, BCBSRI signed on to support the{" "}
                      <a
                        className="standard-link"
                        href="https://www.hrc.org/resources/business-coalition-for-equality"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Business Coalition for Equality Act
                      </a>
                      , federal legislation that would provide the same basic
                      protections to LGBTQ people as are provided to other
                      protected groups under federal law.
                    </li>
                    <li>
                      We continue to encourage all employees to add pronouns to
                      their email signatures. Why?{" "}
                      <a
                        className="standard-link"
                        href="https://soundcloud.com/therhodetohealth/the-power-of-pronouns-season-4-ep-4?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Listen to why pronouns matter
                      </a>
                    </li>
                    <li>
                      We are actively exploring how we can act to improve the
                      safety of pregnancy and childbirth for women of color and
                      provide support for women experiencing postpartum
                      depression.{" "}
                      <a
                        className="standard-link"
                        href="https://www.bcbsri.com/newsroom/viewpoints/where-i-stand-racial-disparities-maternal-health"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Learn what maternal health equity means
                      </a>
                    </li>
                    <li>
                      BCBSRI CEO Martha Wofford signed the{" "}
                      <a
                        className="standard-link"
                        href="https://www.ceoaction.com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        CEO Pledge for Diversity &amp; Inclusion
                      </a>
                      . The pledge outlines a specific set of four commitments
                      organizations will take to cultivate an environment where
                      employees feel comfortable and empowered to have
                      discussions about diversity and inclusion.
                    </li>
                  </ul>
                </div>
              </Row6633>
            </NestedContainer>
          </FullWidthContainer>
        </div>
      </div>
    </Layout>
  );
};

export default CustomersPage;
